// Generated by Framer (4ba8877)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getLoadingLazyAtYPosition, Image, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const serializationHash = "framer-6e6uF"

const variantClassNames = {QxUyoEfcX: "framer-v-rnndbw"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, text, width, ...props}) => { return {...props, R3ArZMX7O: text ?? props.R3ArZMX7O ?? "dsdsada"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;text?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, R3ArZMX7O, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "QxUyoEfcX", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-rnndbw", className, classNames)} data-framer-name={"Default"} layoutDependency={layoutDependency} layoutId={"QxUyoEfcX"} ref={ref ?? ref1} style={{...style}}><motion.div className={"framer-1ovlwk6"} data-framer-name={"Iocn Block"} layoutDependency={layoutDependency} layoutId={"aM4xL3J4D"} style={{backgroundColor: "var(--token-66f20cc5-8ff4-4894-bf74-bf3ca1c56cf0, rgb(122, 77, 248))", borderBottomLeftRadius: 4, borderBottomRightRadius: 4, borderTopLeftRadius: 4, borderTopRightRadius: 4}}><Image background={{alt: "", fit: "fit", loading: getLoadingLazyAtYPosition((((componentViewport?.y || 0) + (0 + ((((componentViewport?.height || 200) - 0) - 29) / 2))) + 6.5)), pixelHeight: 9, pixelWidth: 9, positionX: "center", positionY: "center", src: "https://framerusercontent.com/images/MYmoNTfwYP10vzQ2Os3gujiaA.svg"}} className={"framer-1ughqhw"} layoutDependency={layoutDependency} layoutId={"WRuam7VG3"}/></motion.div><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7UG9wcGlucy1yZWd1bGFy", "--framer-font-family": "\"Poppins\", \"Poppins Placeholder\", sans-serif", "--framer-font-size": "15px", "--framer-line-height": "1.4285714em", "--framer-text-color": "var(--extracted-r6o4lv, var(--token-4549beb9-5668-47c0-ba5b-3042517b1cda, rgb(244, 247, 255)))"}}>30+ Features</motion.p></React.Fragment>} className={"framer-12ycpyn"} data-framer-name={"Text"} fonts={["GF;Poppins-regular"]} layoutDependency={layoutDependency} layoutId={"pxOObF8IQ"} style={{"--extracted-r6o4lv": "var(--token-4549beb9-5668-47c0-ba5b-3042517b1cda, rgb(244, 247, 255))", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} text={R3ArZMX7O} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-6e6uF.framer-qzpzyj, .framer-6e6uF .framer-qzpzyj { display: block; }", ".framer-6e6uF.framer-rnndbw { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 16px; height: min-content; justify-content: flex-start; overflow: hidden; padding: 0px; position: relative; width: 341px; }", ".framer-6e6uF .framer-1ovlwk6 { align-content: center; align-items: center; display: flex; flex: none; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: 29px; justify-content: center; overflow: hidden; padding: 0px; position: relative; width: 29px; will-change: var(--framer-will-change-override, transform); }", ".framer-6e6uF .framer-1ughqhw { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 16px); overflow: hidden; position: relative; width: 16px; }", ".framer-6e6uF .framer-12ycpyn { flex: 1 0 0px; height: auto; position: relative; white-space: pre-wrap; width: 1px; word-break: break-word; word-wrap: break-word; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-6e6uF.framer-rnndbw, .framer-6e6uF .framer-1ovlwk6 { gap: 0px; } .framer-6e6uF.framer-rnndbw > * { margin: 0px; margin-left: calc(16px / 2); margin-right: calc(16px / 2); } .framer-6e6uF.framer-rnndbw > :first-child, .framer-6e6uF .framer-1ovlwk6 > :first-child { margin-left: 0px; } .framer-6e6uF.framer-rnndbw > :last-child, .framer-6e6uF .framer-1ovlwk6 > :last-child { margin-right: 0px; } .framer-6e6uF .framer-1ovlwk6 > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 29
 * @framerIntrinsicWidth 341
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]}}}
 * @framerVariables {"R3ArZMX7O":"text"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramervFRmO4eku: React.ComponentType<Props> = withCSS(Component, css, "framer-6e6uF") as typeof Component;
export default FramervFRmO4eku;

FramervFRmO4eku.displayName = "Feature List 11";

FramervFRmO4eku.defaultProps = {height: 29, width: 341};

addPropertyControls(FramervFRmO4eku, {R3ArZMX7O: {defaultValue: "dsdsada", displayTextArea: false, title: "Text", type: ControlType.String}} as any)

addFonts(FramervFRmO4eku, [{explicitInter: true, fonts: [{family: "Poppins", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/poppins/v21/pxiEyp8kv8JHgFVrFJXUc1NECPY.woff2", weight: "400"}]}], {supportsExplicitInterCodegen: true})